import React, { useEffect } from "react";

const Kvkk = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div
      style={{
        width: "80%",
        margin: "100px 10%",
        fontFamily: "Roboto",
        fontSize: 16,
        lineHeight: 1.3,
      }}
    >
      Gofody Gizlilik Politikası
      <br />
      <br />
      İşbu Gizlilik Politikası’nın amacı, www.gofody.com adresinde yer alan
      siteye (“Site”) üye olarak kaydolan kişiler (“Kullanıcı(lar)”) tarafından
      Site’de yer alan Platform’dan faydalanılması sürecinde Gofody ile
      paylaşılan bilgi ve verilerin kullanımına ilişkin koşul ve şartları tespit
      etmektir. Gizlilik Politikası, Kullanıcı ile akdedilen Kullanıcı
      Sözleşmesi’nin eki ve ayrılmaz bir parçası niteliğindedir.
      <br />
      <br />
      Kullanıcı tarafından Site’ye yüklenen İçerik Kullanıcı’nın
      mülkiyetindedir. Şirket, bu İçerik’i Kullanıcı’nın önceden onayını
      almaksızın açıklamayacak veya satmayacaktır.
      <br />
      <br />
      Şirket, Site üzerinden kendisine elektronik ortamdan iletilen İçerikleri’i
      Kullanıcılar ile yaptığı Kullanıcı Sözleşmesi ile belirlenen amaçlar ve
      kapsam dışında üçüncü kişilere açıklamayacaktır. Bu kapsamda Şirket,
      İçerikler’i kesinlikle özel ve gizli tutmayı, bunu bir sır saklama yükümü
      olarak addetmeyi ve gizliliğin sağlanması ve sürdürülmesi, gizli bilginin
      tamamının veya herhangi bir kısmının kamu alanına girmesini veya yetkisiz
      kullanımını veya üçüncü bir kişiye ifşasını önlemek için gerekli tüm
      tedbirleri almayı ve gerekli özeni göstermeyi taahhüt etmektedir.
      <br />
      <br />
      Kullanıcı’nın Şirket’e onay vermesi halinde ödeme işlemleri, fatura kesimi
      ve işlenmesi, banka entegrasyonu, Kullanıcı’nın erişimine izin verdiği
      üçüncü kişi uygulamaları ve doğrudan pazarlama gibi amaçlarla
      Kulllanıcı’nın verdiği onay çerçevesinde İçerikler işlenebilir,
      saklanabilir ve Şirket ile ortakları tarafından Kullanıcı’nın talep ettiği
      hizmetleri sağlamak üzere üçüncü kişilere devredilebilir.
      <br />
      <br />
      Şirket, İçerikler’i fatura gönderilmesi, ödeme bilgilerinin paylaşılması
      gibi talep edilen hizmetlerin sağlanması amacıyla diğer kullanıcılara
      açıklayabilecektir. Kullanıcı’nın diğer kullanıcıya ait İçerikler’i
      kullanmak istemesi durumunda ilgili kullanıcıdan onay alacak ve
      İçerikler’i alınan bu onay kapsamında kullanacaktır.
      <br />
      <br />
      Kişisel bilgiler Kullanıcı ile temas kurmak veya Kullanıcı’nın Site’deki
      tecrübesini iyileştirmek (hizmetlere ilişkin bakım ve destek, mevcut
      hizmetlerin geliştirilmesi, yeni hizmetler oluşturulması ve kişiye özel
      hizmetler sunulması gibi) amacıyla kullanılabileceği gibi veri tabanı
      oluşturma, üyeliklerin sürdürülmesi ve Şirket’in hizmetlerinin
      Kullanıcı’ya sağlanması için gerekli olan diğer faaliyetleri
      gerçekleştirme amacıyla da işleyebilecek, saklayabilecek ve üçüncü
      kişilere aktarabilecektir.
      <br />
      <br />
      Konum bilginiz, harita üzerinde konumuzun gösterilmesi, Gofody kullanan
      işletmelerin kuryelerini harita üzerinden takip edebilmesi, kuryelerin
      bulunduğu lokasyonun belirlenmesi, herhangi bir sorun da tarafınıza bilgi
      verilmesi amaçlarıyla işlenmektedir.
      <br />
      <br />
      Şirket, Kullanıcı’nın Site üzerinde gerçekleştirdiği kullanım ve işlem
      bilgilerini anonim hale getirerek; istatistiki değerlendirmelerde,
      performans değerlendirmelerinde, Şirket ve iş ortaklarının pazarlama
      kampanyalarında ve bağış kampanyalarında, yıllık rapor ve benzeri
      raporlarda kullanmak üzere bu hedeflerin gerçekleştirilmesi için gereken
      sürede saklayabilir, işleyebilir ve iş ortaklarına iletebilir.
      <br />
      <br />
      Site üzerinden düzenlenebilecek olan periyodik veya periyodik olmayan
      anketlere cevap veren ve kişisel bilgilerinin işlenmesi konusunda rıza
      gösteren Kullanıcılar’dan talep edilen bilgiler, Şirket tarafından bu
      kişilere doğrudan pazarlama yapmak, istatistiki analiz yapmak ve veri
      tabanı oluşturmak amacıyla kullanılabilecektir.
      <br />
      <br />
      Şirket ayrıca, aşağıdaki koşulların varlığı halinde Kullanıcı’ya ait
      bilgileri üçüncü kişilerle paylaşabilecektir:
      <br />
      Kullanıcı Sözleşmesi kapsamındaki yükümlülüklerin yerine getirilmesi için
      ilgili ifşanın zorunlu olması, Sistemle ilgili teknik sorunların
      tanımlanması ve çözülmesi için, Şirket gereken hallerde kullanıcıların IP
      adresini tespit etmek ve kullanmak durumunda kalabilir. IP adresleri,
      ayrıca kullanıcıları genel bir şekilde tanımlamak ve kapsamlı demografik
      bilgi toplamak amacıyla da kullanılabilir.
      <br />
      <br />
      Şirket, yukarıda anılan amaçlarla ilgili verileri Kullanıcı’nın ikamet
      ettiği ülke dışında dünyanın herhangi bir yerinde bulunan sunucularına
      (sunucular kendisine, bağlı şirketlerine veya alt yüklenicilerine ait
      olabilir) aktarma hakkına sahiptir.
      <br />
      <br />
      Site üzerinden başka site ve uygulamalara link verilmesi mümkün olup,
      Şirket’in bu site ve uygulamaların gizlilik uygulamaları ve içeriklerine
      yönelik herhangi bir sorumluluk taşımamaktadır.
      <br />
      <br />
      Şirket, işbu Gizlilik Politikası hükümlerini dilediği zaman Site’de
      yayımlamak suretiyle değiştirebilir. Şirket’in değişiklik yaptığı Gizlilik
      Politikası hükümleri Site’de yayınlandığı tarihte yürürlük kazanır.
    </div>
  );
};

export default Kvkk;
