import React, { useState } from "react";
import classes from "./footer.module.scss";
import { Formik, Form, Field } from "formik";
import Input from "../../components/input";
import Textarea from "../../components/textarea";
import Select from "../../components/select";
import ReactLoading from "react-loading";
import validationSchema from "./validationSchema";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Map from "../map";

import { Link } from "react-router-dom";

const Facebook = process.env.PUBLIC_URL + "/social/facebook.svg";
const Twitter = process.env.PUBLIC_URL + "/social/twitter.svg";
const Linkedin = process.env.PUBLIC_URL + "/social/linkedin.svg";
const Instagram = process.env.PUBLIC_URL + "/social/instagram.svg";
const AddressIcon = process.env.PUBLIC_URL + "/icons/address.svg";
const PhoneIcon = process.env.PUBLIC_URL + "/icons/phone.svg";
const MailIcon = process.env.PUBLIC_URL + "/icons/mail.svg";

const Footer = ({ id, type, history }) => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhonenumber] = useState("");
  const [subject, setSelectedSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const isAllFieldsHasLength =
    name.length != 0 &&
    surname.length != 0 &&
    email.length != 0 &&
    phoneNumber != 0 &&
    subject != 0 &&
    message != 0;

  const subjectOptions = [
    { value: "İş Başvurusu", label: "İş Başvurusu" },
    { value: "Restoran Başvurusu", label: "Restoran Başvurusu" },
    {
      value: "Diğer",
      label: "Diğer",
    },
  ];
  const initialValues = {
    name: name,
    surname: surname,
    email: email,
    phoneNumber: phoneNumber,
    subject: subject,
    message: message,
  };
  return (
    <>
      <div id={id} className={classes.Footer}>
        <>
          <span className={classes.FooterTitle}>Bize Ulaşın</span>
          <div className={classes.FooterContainer}>
            <div className={classes.FooterForm}>
              <div className={classes.contactSection}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flex: 1,
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: 16,
                  }}
                >
                  <img className={classes.contactIcon} src={PhoneIcon} />
                  <span className={classes.FooterText}>0850 241 14 05</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flex: 1,
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <img className={classes.contactIcon} src={MailIcon} />
                  <span className={classes.FooterText}> info@gofody.com</span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <img className={classes.contactIcon} src={AddressIcon} />
                <span className={classes.FooterText}>
                  Vasıf Bey Sk. No:2 Burhaniye, 34676 Üsküdar/İstanbul
                </span>
              </div>
              <Map />
            </div>
            <div className={classes.FooterContent}>
              <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={(values) => {
                  setLoading(true);
                  axios
                    .post(
                      "https://backend.gofody.com/api/v1/potentialcustomer/contact/",
                      {
                        name: values.name,
                        last_name: values.surname,
                        subject: values.subject,
                        message: values.message,
                        phone: values.phoneNumber,
                        email: values.email,
                      }
                    )
                    .then((response) => {
                      setLoading(false);
                      toast.success("Form başarıyla gönderilmiştir.", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                      setName("");
                      setSurname("");
                      setEmail("");
                      setPhonenumber("");
                      setSelectedSubject("");
                      setMessage("");
                    })
                    .catch((error) => {
                      setLoading(false);
                      toast.error(
                        "Form gönderilirken bir hata oluştu.Tekrar deneyiniz.",
                        {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        }
                      );
                    });
                }}
              >
                {({ values, handleChange, errors }) => {
                  return (
                    <Form className={classes.Form}>
                      <span
                        className={classes.FooterText}
                        style={{
                          color: "#000",
                          width: "70%",
                          marginBottom: 48,
                        }}
                      >
                        Tüm soru, görüş ve merak ettiğiniz diğer konular için
                        form doldurabilir ve mesaj gönderebilirsiniz
                      </span>
                      <Field
                        error={errors.name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        value={values.name}
                        placeholder={"İsim"}
                        component={Input}
                      />
                      <div className={classes.left}>
                        <Field
                          error={errors.surname}
                          onChange={(e) => {
                            setSurname(e.target.value);
                          }}
                          value={values.surname}
                          placeholder={"Soyisim"}
                          component={Input}
                        />
                      </div>

                      <Field
                        error={errors.email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        value={values.email}
                        placeholder={"E-mail"}
                        component={Input}
                      />
                      <div className={classes.left}>
                        <Field
                          error={errors.phoneNumber}
                          onChange={(e) => {
                            setPhonenumber(e.target.value);
                          }}
                          value={values.phoneNumber}
                          placeholder={"Telefon No."}
                          component={Input}
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <Field
                          options={subjectOptions}
                          value={subjectOptions.filter(
                            (option) => option.value === values.subject
                          )}
                          onChange={(val) => {
                            setSelectedSubject(val.value);
                          }}
                          tabSelectsValue={values.subject}
                          error={errors.subject}
                          placeholder={"Konu Seçiniz"}
                          component={Select}
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <Field
                          error={errors.message}
                          onChange={(e) => {
                            setMessage(e.target.value);
                          }}
                          value={values.message}
                          placeholder={"Mesaj Yazın"}
                          component={Textarea}
                        />
                      </div>
                      <div
                        style={{
                          width: "99%",
                          justifyContent: "flex-end",
                          display: "flex",
                        }}
                      >
                        <button
                          style={{
                            backgroundColor: isAllFieldsHasLength && "#f1b62a",
                          }}
                          type={"submit"}
                          className={classes.FormButton}
                        >
                          {loading ? (
                            <ReactLoading
                              type={"spinningBubbles"}
                              color={"#000000"}
                              height={"40px"}
                              width={"40px"}
                            />
                          ) : (
                            <span className={classes.formButtonText}>
                              Gönder
                            </span>
                          )}
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
          <ToastContainer />
        </>
        <div className={classes.SocialMedia}>
          <div className={classes.linkContainer}>
            <Link className={classes.kvkk} to="/kvkk" href="kvkk">
              <b>Kişisel Verilerin Korunması</b>
            </Link>
            <Link className={classes.kvkk} to="/kvkk-restoran" href="kvkk">
              <b>Restoran Veri Aktarım İçin Protokol</b>
            </Link>
            <Link className={classes.kvkk} to="/kvkk-kurye" href="kvkk">
              <b>Tedarikçi Çalışanı KVKK Metni</b>
            </Link>
            <Link className={classes.kvkk} to="/kvkk-gizlilik" href="kvkk">
              <b>Gizlilik Politikası</b>
            </Link>
          </div>
          <span
            className={classes.FooterText}
            style={{ fontFamily: "Roboto Bold", textAlign: "center" }}
          >
            ©2021 <span className={classes.whiteText}>Go</span>fody
          </span>
          <div className={classes.socialContainer}>
            <img
              onClick={() => {
                window.open("https://www.facebook.com/gofodyyemek", "_blank");
              }}
              src={Facebook}
              className={classes.SocialMediaIcon}
            />

            <img
              onClick={() => {
                window.open("https://twitter.com/gofodyyemek", "_blank");
              }}
              src={Twitter}
              className={classes.SocialMediaIcon}
            />
            <img
              onClick={() => {
                window.open("https://www.instagram.com/gofodyyemek/", "_blank");
              }}
              src={Instagram}
              className={classes.SocialMediaIcon}
            />
            <img
              src={Linkedin}
              className={classes.SocialMediaIcon}
              onClick={() => {
                window.open(
                  "https://tr.linkedin.com/company/gofodyturkey",
                  "_blank"
                );
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
