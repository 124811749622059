import logo from "./logo.svg";
import "./App.scss";
import Home from "./pages/home";
import Restaurant from "./pages/restaurant";
import Kvkk from "./pages/kvkk";
import Download from "./pages/download";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import LandingPage from "./pages/landingpage";
import Kvkk2 from "./pages/kvkk2";
import Kvkk3 from "./pages/kvkk3";
import Kvkk4 from "./pages/kvkk4";
import CompleteRegistration from "./pages/completeRegistration";

function App({ history }) {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/kvkk">
            <Kvkk />
          </Route>
          <Route exact path="/restaurant">
            <Restaurant />
          </Route>
          <Route exact path="/marketing">
            <LandingPage />
          </Route>
          <Route exact path="/kvkk-kurye">
            <Kvkk2 />
          </Route>
          <Route exact path="/kvkk-restoran">
            <Kvkk3 />
          </Route>
          <Route exact path="/kvkk-gizlilik">
            <Kvkk4 />
          </Route>
          <Route exact path="/download-electron">
            <Download />
          </Route>
          <Route exact path="/restaurant-registration/:code">
            <CompleteRegistration />
          </Route>
          <Route exact path="/restaurant-registration">
            <CompleteRegistration />
          </Route>
          {/* <Route exact path="/apple-app-site-association">
            <AppleAssociation content={`{"applinks":{"apps":[],"details":[{"appID":"1546612606.com.gofody.food.ios","paths":["/*"]}]}}`}/>
          </Route>
          <Route exact path="/.well-known/assetlinks.json" >
             <AppleAssociation content={`[{
  "relation": ["delegate_permission/common.handle_all_urls"],
  "target": {
    "namespace": "android_app",
    "package_name": "com.gofody.food.android",
    "sha256_cert_fingerprints":[
      "C0:72:FD:F1:56:C6:10:A9:A4:F5:56:5A:88:52:CD:6D:54:6E:78:2B:0F:56:0A:39:ED:4B:FA:5F:B0:38:AF:C5",
      "1F:15:A3:9F:7F:08:6F:52:98:0A:27:37:9A:DC:95:BA:D5:BD:C2:DF:29:5D:81:7C:8C:24:C7:EE:CB:63:1A:5A", 
      "5F:96:4B:78:35:A6:D3:DA:39:F7:E9:8D:D2:26:A5:7A:CE:28:4F:21:0C:56:70:DA:B3:78:B2:CE:50:C1:79:67"
    ]
  }
}]`} />
          </Route> */}
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
